import '../styles/TermsPage.css';



export const TermsofUse = (props) => {
    return(
        <>
            <div className="Terms_Script">
                <h2>제1조(목적)</h2>
                <p>이 약관은 DS오토라인이 운영하는 사이버 몰(이하 “몰”이라 한다)에서 제공하는 인터넷 관련 서비스(이하 “서비스”라 한다)를 이용함에 있어 사이버 몰과 이용자의 권리․의무 및 책임사항을 규정함을 목적으로 합니다.</p>
                <p>※「PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.」</p>
                
                <h2>제2조(정의)</h2>
                <p><span class="number-style">①</span>“몰”이란 DS오토라인이 재화 또는 용역(이하 “재화 등”이라 함)을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이버몰을 운영하는 사업자의 의미로도 사용합니다.</p>
                <p><span class="number-style">②</span> “이용자”란 “몰”에 접속하여 이 약관에 따라 “몰”이 제공하는 서비스를 받는 회원 및 비회원을 말합니다.</p>
                <p><span class="number-style">③</span> ‘회원’이라 함은 “몰”에 회원등록을 한 자로서, 계속적으로 “몰”이 제공하는 서비스를 이용할 수 있는 자를 말합니다.</p>
                <p><span class="number-style">④</span> ‘비회원’이라 함은 회원에 가입하지 않고 “몰”이 제공하는 서비스를 이용하는 자를 말합니다.</p>
                
                <h2>제3조 (약관 등의 명시와 설명 및 개정)</h2>
                <p><span class="number-style">①</span> “몰”은 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호․모사전송번호․전자우편주소, 사업자등록번호, 통신판매업 신고번호, 개인정보관리책임자등을 이용자가 쉽게 알 수 있도록 사이버몰의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.</p>
                <p><span class="number-style">②</span> “몰은 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회․배송책임․환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다.</p>
                <p><span class="number-style">③</span> “몰”은 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</p>
                <p><span class="number-style">④</span> “몰”이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 몰의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다.  이 경우 "몰“은 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.</p>
                <p><span class="number-style">⑤</span> “몰”이 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에 “몰”에 송신하여 “몰”의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.</p>
                <p><span class="number-style">⑥</span> 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및 관계법령 또는 상관례에 따릅니다.</p>
                
                <h2>제4조(서비스의 제공 및 변경)</h2>
                <p><span class="number-style">①</span> “몰”은 다음과 같은 업무를 수행합니다.</p>
                <ul>
                    <li>재화 또는 용역에 대한 정보 제공 및 구매계약의 체결</li>
                    <li>구매계약이 체결된 재화 또는 용역의 배송</li>
                    <li>기타 “몰”이 정하는 업무</li>
                </ul>
                <p><span class="number-style">②</span> “몰”은 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.</p>
                <p><span class="number-style">③</span> “몰”이 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로 즉시 통지합니다.</p>
                <p><span class="number-style">④</span> 전항의 경우 “몰”은 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, “몰”이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.</p>
                
                <h2>제5조(서비스의 중단)</h2>
                <p><span class="number-style">①</span> “몰”은 컴퓨터 등 정보통신설비의 보수점검․교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.</p>
                <p><span class="number-style">②</span> “몰”은 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, “몰”이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.</p>
                <p><span class="number-style">③</span> 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 “몰”은 제8조에 정한 방법으로 이용자에게 통지하고 당초 “몰”에서 제시한 조건에 따라 소비자에게 보상합니다. 다만, “몰”이 보상기준 등을 고지하지 아니한 경우에는 이용자들의 마일리지 또는 적립금 등을 “몰”에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급합니다.</p>
                
                <h2>제6조(회원가입)</h2>
                <p><span class="number-style">①</span> 이용자는 “몰”이 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.</p>
                <p><span class="number-style">②</span> “몰”은 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.</p>
                <ul>
                    <li>가입신청자가 이 약관 제7조제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 “몰”의 회원재가입 승낙을 얻은 경우에는 예외로 한다.</li>
                    <li>등록 내용에 허위, 기재누락, 오기가 있는 경우</li>
                    <li>기타 회원으로 등록하는 것이 “몰”의 기술상 현저히 지장이 있다고 판단되는 경우</li>
                </ul>
                <p><span class="number-style">③</span> 회원가입계약의 성립 시기는 “몰”의 승낙이 회원에게 도달한 시점으로 합니다.</p>
                <p><span class="number-style">④</span> 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 “몰”에 대하여 회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.</p>
                
                <h2>제7조(회원 탈퇴 및 자격 상실 등)</h2>
                <p><span class="number-style">①</span> 회원은 “몰”에 언제든지 탈퇴를 요청할 수 있으며 “몰”은 즉시 회원탈퇴를 처리합니다.</p>
                <p><span class="number-style">②</span> 회원이 다음 각 호의 사유에 해당하는 경우, “몰”은 회원자격을 제한 및 정지시킬 수 있습니다.</p>
                <ul>
                    <li>가입 신청 시에 허위 내용을 등록한 경우</li>
                    <li>“몰”을 이용하여 구입한 재화 등의 대금, 기타 “몰”이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우</li>
                    <li>다른 사람의 “몰” 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우</li>
                    <li>“몰”을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우</li>
                </ul>
                <p><span class="number-style">③</span> “몰”이 회원 자격을 제한․정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 “몰”은 회원자격을 상실시킬 수 있습니다.</p>
                <p><span class="number-style">④</span> “몰”이 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.</p>
                
                <h2>제8조(회원에 대한 통지)</h2>
                <p><span class="number-style">①</span> “몰”이 회원에 대한 통지를 하는 경우, 회원이 “몰”과 미리 약정하여 지정한 전자우편 주소로 할 수 있습니다.</p>
                <p><span class="number-style">②</span> “몰”은 불특정다수 회원에 대한 통지의 경우 1주일이상 “몰” 게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.</p>
                
                <h2>제9조(구매신청 및 개인정보 제공 동의 등)</h2>
                <p><span class="number-style">①</span> “몰”이용자는 “몰”상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, “몰”은 이용자가 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.</p>
                <ul>
                    <li>재화 등의 검색 및 선택</li>
                    <li>받는 사람의 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력</li>
                    <li>약관내용, 청약철회권이 제한되는 서비스, 배송료․설치비 등의 비용부담과 관련한 내용에 대한 확인</li>
                    <li>이 약관에 동의하고 위 3.호의 사항을 확인하거나 거부하는 표시 (예, 마우스 클릭)</li>
                    <li>재화등의 구매신청 및 이에 관한 확인 또는 “몰”의 확인에 대한 동의</li>
                    <li>결제방법의 선택</li>
                </ul>
                <p><span class="number-style">②</span> “몰”이 제3자에게 구매자 개인정보를 제공할 필요가 있는 경우 1) 개인정보를 제공받는 자, 2)개인정보를 제공받는 자의 개인정보 이용목적, 3) 제공하는 개인정보의 항목, 4) 개인정보를 제공받는 자의 개인정보 보유 및 이용기간을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.)</p>
                <p><span class="number-style">③</span> “몰”이 제3자에게 구매자의 개인정보를 취급할 수 있도록 업무를 위탁하는 경우에는 1) 개인정보 취급위탁을 받는 자, 2) 개인정보 취급위탁을 하는 업무의 내용을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.) 다만, 서비스제공에 관한 계약이행을 위해 필요하고 구매자의 편의증진과 관련된 경우에는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에서 정하고 있는 방법으로 개인정보 취급방침을 통해 알림으로써 고지절차와 동의절차를 거치지 않아도 됩니다.</p>
                
                <h2>제10조 (계약의 성립)</h2>
                <p><span class="number-style">①</span> “몰”은 제9조와 같은 구매신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.</p>
                <ul>
                    <li>신청 내용에 허위, 기재누락, 오기가 있는 경우</li>
                    <li>미성년자가 담배, 주류 등 청소년보호법에서 금지하는 재화 및 용역을 구매하는 경우</li>
                    <li>기타 구매신청에 승낙하는 것이 “몰” 기술상 현저히 지장이 있다고 판단하는 경우</li>
                </ul>
                <p><span class="number-style">②</span> “몰”의 승낙이 제12조 제1항의 수신 확인 통지 형태로 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.</p>
                <p><span class="number-style">③</span> “몰”의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정 취소 등에 관한 정보 등을 포함하여야 합니다.</p>
                
                <h2>제11조(지급방법)</h2>
                <p>“몰”에서 구매한 재화 또는 용역에 대한 대금 지급 방법은 다음 각 호의 방법중 가용한 방법으로 할 수 있습니다. 단, “몰”은 이용자의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도 추가하여 징수할 수 없습니다.</p>
                <ul>
                    <li>폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체</li>
                    <li>선불카드, 직불카드, 신용카드 등의 각종 카드 결제</li>
                    <li>온라인무통장입금</li>
                    <li>전자화폐에 의한 결제</li>
                    <li>수령 시 대금지급</li>
                    <li>마일리지 등 “몰”이 지급한 포인트에 의한 결제</li>
                    <li>“몰”과 계약을 맺었거나 “몰”이 인정한 상품권에 의한 결제</li>
                    <li>기타 전자적 지급 방법에 의한 대금 지급 등</li>
                </ul>
                
                <h2>제12조(수신확인통지․구매신청 변경 및 취소)</h2>
                <p><span class="number-style">①</span> “몰”은 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다.</p>
                <p><span class="number-style">②</span> 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있고 “몰”은 배송 전에 이용자의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 제15조의 청약철회 등에 관한 규정에 따릅니다.</p>
                
                <h2>제13조(재화 등의 공급)</h2>
                <p><span class="number-style">①</span> “몰”은 이용자와 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상, 이용자가 청약을 한 날부터 7일 이내에 재화 등을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다. 다만, “몰”이 이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은 날부터 3영업일 이내에 조치를 취합니다.  이때 “몰”은 이용자가 재화 등의 공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.</p>
                <p><span class="number-style">②</span> “몰”은 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을 명시합니다. 만약 “몰”이 약정 배송기간을 초과한 경우에는 그로 인한 이용자의 손해를 배상하여야 합니다. 다만 “몰”이 고의․과실이 없음을 입증한 경우에는 그러하지 아니합니다.</p>
                
                <h2>제14조(환급)</h2>
                <p>“몰”은 이용자가 구매신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터 3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.</p>
                
                <h2>제15조(청약철회 등)</h2>
                <p><span class="number-style">①</span> “몰”과 재화등의 구매에 관한 계약을 체결한 이용자는 「전자상거래 등에서의 소비자보호에 관한 법률」 제13조 제2항에 따른 계약내용에 관한 서면을 받은 날(그 서면을 받은 때보다 재화 등의 공급이 늦게 이루어진 경우에는 재화 등을 공급받거나 재화 등의 공급이 시작된 날을 말합니다)부터 7일 이내에는 청약의 철회를 할 수 있습니다. 다만, 청약철회에 관하여 「전자상거래 등에서의 소비자보호에 관한 법률」에 달리 정함이 있는 경우에는 동 법 규정에 따릅니다.</p>
                <p><span class="number-style">②</span> 이용자는 재화 등을 배송 받은 경우 다음 각 호의 1에 해당하는 경우에는 반품 및 교환을 할 수 없습니다.</p>
                <ul>
                    <li>이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우(다만, 재화 등의 내용을 확인하기 위하여 포장 등을 훼손한 경우에는 청약철회를 할 수 있습니다)</li>
                    <li>이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우</li>
                    <li>시간의 경과에 의하여 재판매가 곤란할 정도로 재화등의 가치가 현저히 감소한 경우</li>
                    <li>같은 성능을 지닌 재화 등으로 복제가 가능한 경우 그 원본인 재화 등의 포장을 훼손한 경우</li>
                </ul>
                <p><span class="number-style">③</span> 제2항제2호 내지 제4호의 경우에 “몰”이 사전에 청약철회 등이 제한되는 사실을 소비자가 쉽게 알 수 있는 곳에 명기하거나 시용상품을 제공하는 등의 조치를 하지 않았다면 이용자의 청약철회 등이 제한되지 않습니다.</p>
                <p><span class="number-style">④</span> 이용자는 제1항 및 제2항의 규정에 불구하고 재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 재화 등을 공급받은 날부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.</p>
                
                <h2>제16조(청약철회 등의 효과)</h2>
                <p><span class="number-style">①</span> “몰”은 이용자로부터 재화 등을 반환받은 경우 3영업일 이내에 이미 지급받은 재화 등의 대금을 환급합니다. 이 경우 “몰”이 이용자에게 재화등의 환급을 지연한때에는 그 지연기간에 대하여 「전자상거래 등에서의 소비자보호에 관한 법률 시행령」제21조의2에서 정하는 지연이자율을 곱하여 산정한 지연이자를 지급합니다.</p>
                <p><span class="number-style">②</span> “몰”은 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다.</p>
                <p><span class="number-style">③</span> 청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은 이용자가 부담합니다. “몰”은 이용자에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 재화 등의 반환에 필요한 비용은 “몰”이 부담합니다.</p>
                <p><span class="number-style">④</span> 이용자가 재화 등을 제공받을 때 발송비를 부담한 경우에 “몰”은 청약철회 시 그 비용을 누가 부담하는지를 이용자가 알기 쉽도록 명확하게 표시합니다.</p>
                
                <h2>제17조(개인정보보호)</h2>
                <p><span class="number-style">①</span> “몰”은 이용자의 개인정보 수집시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.</p>
                <p><span class="number-style">②</span> “몰”은 회원가입시 구매계약이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여 구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정 개인정보를 수집하는 경우에는 그러하지 아니합니다.</p>
                <p><span class="number-style">③</span> “몰”은 이용자의 개인정보를 수집·이용하는 때에는 당해 이용자에게 그 목적을 고지하고 동의를 받습니다.</p>
                <p><span class="number-style">④</span> “몰”은 수집된 개인정보를 목적외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용·제공단계에서 당해 이용자에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.</p>
                <p><span class="number-style">⑤</span> “몰”이 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제22조제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.</p>
                <p><span class="number-style">⑥</span> 이용자는 언제든지 “몰”이 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 “몰”은 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 “몰”은 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.</p>
                <p><span class="number-style">⑦</span> “몰”은 개인정보 보호를 위하여 이용자의 개인정보를 취급하는 자를 최소한으로 제한하여야 하며 신용카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.</p>
                <p><span class="number-style">⑧</span> “몰” 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.</p>
                <p><span class="number-style">⑨</span> “몰”은 개인정보의 수집·이용·제공에 관한 동의 란을 미리 선택한 것으로 설정해두지 않습니다. 또한 개인정보의 수집·이용·제공에 관한 이용자의 동의거절시 제한되는 서비스를 구체적으로 명시하고, 필수수집항목이 아닌 개인정보의 수집·이용·제공에 관한 이용자의 동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.</p>
                
                <h2>제18조(“몰“의 의무)</h2>
                <p><span class="number-style">①</span> “몰”은 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화․용역을 제공하는데 최선을 다하여야 합니다.</p>
                <p><span class="number-style">②</span> “몰”은 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.</p>
                <p><span class="number-style">③</span> “몰”이 상품이나 용역에 대하여 「표시․광고의 공정화에 관한 법률」 제3조 소정의 부당한 표시․광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.</p>
                <p><span class="number-style">④</span> “몰”은 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.</p>
                
                <h2>제19조(회원의 ID 및 비밀번호에 대한 의무)</h2>
                <p><span class="number-style">①</span> 제17조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.</p>
                <p><span class="number-style">②</span> 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.</p>
                <p><span class="number-style">③</span> 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 “몰”에 통보하고 “몰”의 안내가 있는 경우에는 그에 따라야 합니다.</p>
                
                <h2>제20조(이용자의 의무)</h2>
                <p>이용자는 다음 행위를 하여서는 안 됩니다.</p>
                <ul>
                    <li>신청 또는 변경시 허위 내용의 등록</li>
                    <li>타인의 정보 도용</li>
                    <li>“몰”에 게시된 정보의 변경</li>
                    <li>“몰”이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시</li>
                    <li>“몰” 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
                    <li>“몰” 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
                    <li>외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 몰에 공개 또는 게시하는 행위</li>
                </ul>
                
                <h2>제21조(연결“몰”과 피연결“몰” 간의 관계)</h2>
                <p><span class="number-style">①</span> 상위 “몰”과 하위 “몰”이 하이퍼링크(예: 하이퍼링크의 대상에는 문자, 그림 및 동화상 등이 포함됨)방식 등으로 연결된 경우, 전자를 연결 “몰”(웹 사이트)이라고 하고 후자를 피연결 “몰”(웹사이트)이라고 합니다.</p>
                <p><span class="number-style">②</span> 연결“몰”은 피연결“몰”이 독자적으로 제공하는 재화 등에 의하여 이용자와 행하는 거래에 대해서 보증 책임을 지지 않는다는 뜻을 연결“몰”의 초기화면 또는 연결되는 시점의 팝업화면으로 명시한 경우에는 그 거래에 대한 보증 책임을 지지 않습니다.</p>
                
                <h2>제22조(저작권의 귀속 및 이용제한)</h2>
                <p><span class="number-style">①</span> “몰“이 작성한 저작물에 대한 저작권 기타 지적재산권은 ”몰“에 귀속합니다.</p>
                <p><span class="number-style">②</span> 이용자는 “몰”을 이용함으로써 얻은 정보 중 “몰”에게 지적재산권이 귀속된 정보를 “몰”의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.</p>
                <p><span class="number-style">③</span> “몰”은 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.</p>
                
                <h2>제23조(분쟁해결)</h2>
                <p><span class="number-style">①</span> “몰”은 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치․운영합니다.</p>
                <p><span class="number-style">②</span> “몰”은 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.</p>
                <p><span class="number-style">③</span> “몰”과 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.</p>
                
                <h2>제24조(재판권 및 준거법)</h2>
                <p><span class="number-style">①</span> “몰”과 이용자 간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.</p>
                <p><span class="number-style">②</span> “몰”과 이용자 간에 제기된 전자상거래 소송에는 한국법을 적용합니다.</p>
                
                <h5>부칙(시행일) 이 약관은 2024-09-10일부터 시행합니다.</h5>
            </div>
        </>
    )
}

export const TermsofInformation = (props) => {
    return(
        <>
            <div className="Terms_Script">
                <p>주)디에스오토라인 (이하 "회사"라 함)은 이용자들의 개인정보 보호를 매우 중요시하며, 이용자가 회사의 서비스 (이하 "(주)디에스오토라인)을 이용함과 동시에 회사에 제공한 개인정보가 보호 받을 수 있도록 최선을 다하고 있습니다.</p>

                <p>이에 회사는 통신비밀보호법, 전기통신사업법, 정보통신망이용촉진 등에 관한법률 등 정보통신서비스제공자가 준수하여야 할 관련 법규상의 개인정보보호규정 및 정보통신부가 제정한 개인정보보호지침을 준수하고 있습니다.</p>

                <p>회사의 개인정보보호정책은 정부의 법률 및 지침 변경이나 회사의 내부방침 변경 등으로 인하여 수시로 변경될 수 있고, 이에 따른 개인정보 보호정책의 지속적인 개선을 위하여 필요한 절차를 정하고 있습니다.</p>

                <p>㈜ 디에스 오토라인의 개인정보 보호정책은 아래에 명시된 내용을 담고 있습니다.</p>

                <h3>1. 개인정보 수집에 대한 동의</h3>
                <p>*회사는 이용자들이 회사의 개인정보보호정책 및 이용약관의 내용에 대하여 「동의함」 또는 「동의하지 않음」 버튼을 클릭할 수 있는 절차를 마련하여, 「동의함」 버튼을 클릭하면 개인정보 수집에 대해 동의한 것으로 봅니다.</p>

                <h3>2. 회사가 수집하는 개인정보</h3>
                <p>(수집목적, 수집항목, 보유기간에 숫자는 같은 정보를 나타냅니다. 즉 수집목적의 <span>1</span>번 수집항목의 <span>1</span>번 보유기간의 <span>1</span>번은 같은 틀에 있는 정보입니다)</p>

                <h2>수집목적</h2>
                <ul>
                    <li><span>1.</span> 장기렌터카, 리스, 할부 등 견적상담</li>
                    <li><span>2.</span> 서비스 제공에 관한 계약 실행</li>
                    <li><span>3.</span> ㈜디에스오토라인 차량 견적신청, 견적회신 및 상담, 장기대여 계약의 체결/이행/관리</li>
                    <li><span>4.</span> 이벤트 광고 및 프로모션</li>
                    <li><span>5.</span> 각종 컴플레인 및 문의 관리</li>
                    <li><span>6.</span> 렌트카 및 리스 할부 계약 진행 및 체결</li>
                </ul>

                <h2>수집항목</h2>
                <ul>
                    <li><span>1.</span> 이름, 성별, 휴대전화번호, 생년월일, 주소, 운전면허번호, 이메일주소</li>
                    <li><span>2.</span> 이름, 성별, 휴대전화번호, 생년월일, 주소, 운전면허번호, 이메일주소</li>
                    <li><span>3.</span> 이름, 성별, 휴대전화번호, 생년월일, 주소, 운전면허번호, 이메일주소</li>
                    <li><span>4.</span> 이름, 성별, 휴대전화번호, 생년월일, 주소, 운전면허번호, 이메일주소</li>
                    <li><span>5.</span> 이름, 성별, 휴대전화번호, 생년월일, 주소, 운전면허번호, 이메일주소</li>
                    <li><span>6.</span> 성별, 연락처, 이메일주소, 운전면허번호</li>
                </ul>

                <h2>보유기간</h2>
                <ul>
                    <li><span>1.</span> 서비스 공급 완료 및 계약 완료 시부터 5년</li>
                    <li><span>2.</span> 서비스 공급 완료 및 계약 완료 시부터 5년</li>
                    <li><span>3.</span> 서비스 공급 완료 및 계약 완료 시부터 5년</li>
                    <li><span>4.</span> 동의일로부터 5년</li>
                    <li><span>5.</span> 동의일로부터 5년</li>
                    <li><span>6.</span> 계약 종료 후 5년</li>
                </ul>

                <h3>3. 서비스 이용 시 수집하는 개인정보</h3>
                <p>서비스 이용과정에서 추가로 생성되는 다음의 회원의 정보들을 수집할 수 있습니다.</p>
                <ul>
                    <li>IP주소, 쿠키, 기기식별번호, 서비스 이용 기록, 방문 기록, 접속 로그, 불량 이용 기록 등 정보</li>
                </ul>

                <h3>4. 민감한 개인정보의 수집 금지 등</h3>
                <p>회사는 회원의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종, 사상, 신조, 정치적 성향, 범죄기록, 의료정보 등)는 수집하지 않으며, 회원이 선택 항목의 정보를 입력하지 않은 경우에도 회사가 제공하는 서비스 이용에 제한은 없습니다.</p>

                <h3>5. 개인정보 수집 방법</h3>
                <p>회사는 다음 각 호의 방법으로 개인정보를 수집합니다.</p>
                <ul>
                    <li><span>1.</span> 회사 웹사이트, 앱, 서면 양식, 전화/팩스 등 서비스 가입이나 사용 중 회원의 자발적 제공을 통한 수집</li>
                    <li><span>2.</span> 생성 정보 수집 툴을 통한 수집</li>
                </ul>

                <h3>6. 개인정보 수집 및 이용 목적</h3>
                <p>회사는 다음 각 호의 목적으로 회원의 개인정보를 수집 및 이용합니다. 수집한 개인정보는 다음의 목적 이외의 용도로 사용되지 않으며 이용 목적이 변경될 시에는 별도의 사전동의를 구합니다.</p>
                <ul>
                    <li><span>1.</span> 회원가입 및 관리 : 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인 확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 각종 고지·통지, 고충처리, 분쟁 조정을 위한 기록 보존 등의 목적</li>
                    <li><span>2.</span> 민원사무의 처리 : 민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 등의 목적</li>
                    <li><span>3.</span> 서비스의 제공 : 서비스 제공, 본인 인증, 연령 인증 등의 목적</li>
                    <li><span>4.</span> 마케팅 및 광고에의 활용 : 신규 서비스 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.</li>
                </ul>

                <h3>7. 개인정보 제3자 제공의 기본 원칙</h3>
                <p>회사는 회원들의 개인정보를 제 6번에 고지한 범위 내에서 사용하며, 회원의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 회원의 개인정보를 제 3자에 제공하거나 외부에 공개하지 않습니다.</p>

                <h3>8. 개인정보 보유 및 이용기간의 기본 원칙</h3>
                <p>원칙적으로 회원의 개인정보는 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기됩니다.</p>

                <h3>9. 개인정보의 취급 위탁</h3>
                <p>회사는 이용자에게 다양한 서비스를 제공하는 데에 반드시 필요한 업무 중 일부를 외부 업체로 하여금 수행하도록 개인정보를 위탁하고 있습니다. 그리고 위탁 받은 업체가 개인정보보호 관계 법령을 위반하지 않도록 관리·감독하고 있습니다.</p>

                <p>위탁업무내용 : sms 발송업무, 알림톡<br/>
                수탁업체 : 세종네트윅스<br/>
                개인정보의 보유 및 이용기간 : 회원탈퇴시 혹은 위탁계약 종료시까지</p>

                <h3>10. 관련 법령에 의한 개인정보의 보유</h3>
                <p>회사는 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 다음 각 호에 따라 회원의 해당 개인정보를 보관하며, 그 목적의 범위 내에서만 이를 이용합니다. 단, 회사 이용계약에 의해 이용계약이 존속 중인 회원(탈퇴하지 아니한 회원)의 경우 보관기간은 보존의무기간 이상 보관할 수 있으며, 해당 기간이 경과된 기록에 대해서는 회원의 삭제 요청이 있는 경우 파기합니다.</p>
                <ul>
                    <li><span>1.</span> 계약 또는 청약철회 등에 관한 기록 : 전자상거래 등에서의 소비자보호에 관한 법률에 따라 5년간 보존</li>
                    <li><span>2.</span> 대금결제 및 재화 등의 공급에 관한 기록 : 전자상거래 등에서의 소비자보호에 관한 법률에 따라 5년간 보존</li>
                    <li><span>3.</span> 소비자의 불만 또는 분쟁 처리에 관한 기록 : 전자상거래 등에서의 소비자보호에 관한 법률에 따라 3년간 보존</li>
                    <li><span>4.</span> 표시·광고에 관한 기록 : 전자상거래 등에서의 소비자보호에 관한 법률에 따라 6개월간 보존</li>
                    <li><span>5.</span> 본인확인에 관한 기록 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률에 따라 6개월간 보존</li>
                    <li><span>6.</span> 전기통신일시, 개시·종료시간, 가입자 번호, 사용 도수, 발신 기지국 위치추적자료에 관한 기록 : 통신비밀보호법에 따라 1년간 보존</li>
                    <li><span>7.</span> 컴퓨터통신, 인터넷 로그기록자료, 접속지 추적 자료 : 통신비밀보호법에 따라 3개월간 보존</li>
                    <li><span>8.</span> 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년</li>
                </ul>

                <h3>11. 개인정보의 파기 절차 및 방법</h3>
                <p>회사는 원칙적으로 개인정보 이용 목적이 달성된 경우에는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 지체 없이 해당 개인정보를 파기하며 파기의 절차, 기한 및 방법은 다음과 같습니다.</p>
                <ul>
                    <li><span>1.</span> 파기 절차 : 이용자가 입력한 정보는 목적 달성 후 전용 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 즉시 파기됩니다. DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.</li>
                    <li><span>2.</span> 파기 기한 : 이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 이용 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.</li>
                    <li><span>3.</span> 파기 방법 : 종이에 기록, 저장된 개인정보는 분쇄기로 분쇄하며, 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없도록 기술적 방법을 사용하여 삭제합니다.</li>
                </ul>

                <h3>12. 개인정보의 수집, 이용, 제공에 대한 동의 철회</h3>
                <p>회원은 여러가지 경로 등을 통해 개인정보의 수집, 이용, 제공에 대하여 동의한 내용을 언제든지 수정 요청하거나 철회 할 수 있습니다.</p>

                <h3>13. 회원의 권리와 행사방법</h3>
                <p>회원은 회사에 대해 언제든지 다음과 각 호와 같은 권리를 행사할 수 있습니다.</p>
                <ul>
                    <li><span>1.</span> 개인정보 열람 요구</li>
                    <li><span>2.</span> 오류 등이 있을 경우 정정 요구</li>
                    <li><span>3.</span> 삭제 요구</li>
                    <li><span>4.</span> 처리 정지 요구</li>
                </ul>

                <p>첫번째 항에 따른 권리 행사는 회사에 대해 개인정보 보호법 시행규칙 별지 제8호 서식에 따라 서면, 전자우편 등을 통하여 하실 수 있으며, 회사는 본인 확인을 위한 요청인의 신분증 사본 등의 증표를 제시 받아 해당 요구가 진정한 본인의 의사인지 여부를 확인할 수 있으며, 본인으로 확인되고 개인정보에 오류가 있거나 보존기간을 경과한 것이 판명되는 등 정정 또는 삭제할 필요가 있다고 인정되는 경우 지체 없이 그에 따른 조치를 취합니다.</p>

                <p>회원이 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다. 첫번째 항에 따른 권리 행사는 회원의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하여야 합니다.</p>

                <h3>14. 회사의 개인정보 열람 및 이용 제한</h3>
                <ul>
                    <li><span>1.</span> 회원 또는 법정대리인의 요구에 의해 해지 또는 삭제, 정정된 개인정보는 위 사항에 명시된 바에 따라 처리되고, 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.</li>
                    <li><span>2.</span> 회원 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며 가입 해지를 요청할 수도 있습니다.</li>
                </ul>

                <h3>15. 해킹 등에 대비한 대책</h3>
                <p>회사는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.</p>
                <ul>
                    <li><span>1.</span> 개인정보 취급 직원의 최소화 및 교육 : 개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.</li>
                    <li><span>2.</span> 정기적인 자체 감사 실시 : 개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.</li>
                    <li><span>3.</span> 내부관리계획의 수립 및 시행 : 개인정보의 안전한 처리를 위하여 내부관리계획을 수립하였습니다.</li>
                    <li><span>4.</span> 개인정보의 암호화 : 이용자의 개인정보는 비밀번호는 암호화되어 저장 및 관리되고 있어, 본인만 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.</li>
                    <li><span>5.</span> 해킹 등에 대비한 기술적 대책 : 회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적으로 갱신·점검을 하며, 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.</li>
                    <li><span>6.</span> 개인정보에 대한 접근 제한 : 개인정보를 처리하는 데이터베이스시스템에 대해서 상급자 한정으로 접근 권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.</li>
                    <li><span>7.</span> 접속 기록의 보관 및 위변조 방지 : 개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다.</li>
                    <li><span>8.</span> 문서보안을 위한 잠금 장치 사용 : 개인정보가 포함된 서류, 보조저장매체 등을 잠금 장치가 있는 안전한 장소에 보관하고 있습니다.</li>
                    <li><span>9.</span> 비인가자에 대한 출입 통제 : 개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입제한 절차를 수립, 운영하고 있습니다.</li>
                </ul>

                <h3>16. 취급 직원의 최소화 및 교육</h3>
                <p>회사의 개인정보 관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 회사는 개인정보 처리방침의 준수 및 예방을 항상 강조하고 있습니다.</p>

                <h3>17. 개인정보관리 책임자 및 담당자</h3>
                <p>회원은 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호에 대한 문의, 불만해결, 피해구제 등 관련 민원을 다음 각 호의 개인정보보호책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.</p>
                <p>[개인정보 보호 책임자]</p>
                <ul>
                    <li>이름: 노주영</li>
                    <li>직책: 대표</li>
                    <li>연락처: 010-6728-8891 / shgkdlads@naver.com</li>
                </ul>
                <p>기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하실 수 있습니다.</p>
                <ul>
                    <li>개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)</li>
                    <li>대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301)</li>
                    <li>경찰청 사이버안전국 (www.police.go.kr / 국번없이 182)</li>
                </ul>

                <h3>18. 개인정보 처리방침 관련 고지 및 통지 방법</h3>
                <ul>
                    <li><span>1.</span> 회사는 현행 개인정보 처리방침에 대한 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 전부터 홈페이지를 통해 변경 이유 및 그 내용을 고지합니다. 다만, 개인정보의 수집 및 활용 등에 있어 이용자 권리의 중요한 변경이 있을 경우에는 최소 30일 전에 고지합니다.</li>
                    <li><span>2.</span> 회사는 회원이 동의한 범위를 넘어 회원의 개인정보를 이용하거나 제3자에게 취급위탁하기 위해 회원의 추가적인 동의를 얻고자 하는 때에는 미리 회원에게 개별적으로 서면, 전자우편, 전화 등으로 해당사항을 고지합니다.</li>
                    <li><span>3.</span> 회사는 개인정보의 수집, 보관, 처리, 이용, 제공, 관리, 파기 등을 제3자에게 위탁하는 경우에는 개인정보 처리방침 등을 통하여 그 사실을 회원에게 고지합니다.</li>
                </ul>

                <h5>부칙 본 개인정보처리방침은 2024년 9월 10일부터 시행됩니다.</h5>

            </div>
        </>
    )
}